module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"painesville-cu","short_name":"Painesville CU","start_url":"/","background_color":"#FFFFFF","theme_color":"#A39161","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78acf0b7db96f462fb3e8fdc9333ac68"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
